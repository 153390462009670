import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useForm } from 'react-hubspot';

import { Links } from '@/utils/constants';
import * as styled from './Content.styled';

import { Breadcrumbs, Layout, Form, Icon, Name, BlurBox } from '../general';
import Container from '../Container';
import { PARTNER_CHALLENGES } from './constants';
import { Title, UnderTitle } from '../general/FormPages.styled';

export const Content = () => {
  const theme = useContext(ThemeContext);

  const formData = useForm({
    portalId: process.env.GATSBY_HUBSPOT_PORTAL_ID,
    formId: process.env.GATSBY_HUBSPOT_TALK_TO_EXPERTS_FORM_ID,
  });

  return (
    <Layout>
      <Breadcrumbs links={[Links.PARTNERS_PROGRAM]} />
      <BlurBox>
        <Title>CodePlatform Partners Program</Title>
        <UnderTitle>
          We get the request - you get the lead. Explore new market
          opportunities and accelerate development as a part or a partner of our
          growing ecosystem.
        </UnderTitle>
        <styled.ContactUs to={Links.TALK}>Contact Us</styled.ContactUs>
      </BlurBox>
      <styled.BlueSection>
        <styled.BlueSectionRound orientation="top">
          <Icon name={Name.GENERAL_HARDEST_ROUND} />
        </styled.BlueSectionRound>
        <Container>
          <styled.BlueContent>
            <styled.BlueTitle>What type of partner are you?</styled.BlueTitle>
            <styled.BlueGrid>
              {PARTNER_CHALLENGES.map((challenge) => (
                <styled.GridItem key={challenge.title}>
                  <styled.GridIconBox>
                    <Icon
                      name={challenge.icon}
                      color={theme.colors.white}
                      width={90}
                      height={90}
                    />
                  </styled.GridIconBox>
                  <styled.GridTitle>{challenge.title}</styled.GridTitle>
                  <styled.GridDescription>
                    {challenge.description}
                  </styled.GridDescription>
                </styled.GridItem>
              ))}
            </styled.BlueGrid>
            <styled.BlueBlurEllipse />
          </styled.BlueContent>
        </Container>
        <styled.BlueSectionRound orientation="bottom">
          <Icon name={Name.GENERAL_HARDEST_ROUND} />
        </styled.BlueSectionRound>
      </styled.BlueSection>
      {/* <styled.TableSection>
        <styled.TableAnchor id="codeplatform-vs-custom-development" />
        <styled.TableTItle>
          CodePlatform VS Custom Development
        </styled.TableTItle>
        <UnderTitle style={{ maxWidth: '820px' }}>
          CodePlatform saves at least 480 hours of mobile application
          development by excluding an expensive initial development phase. It
          covers all you need to start with. Even the pitfalls.
        </UnderTitle>
        <styled.TableBox>
          <Table />
        </styled.TableBox>
      </styled.TableSection> */}
      <styled.FormSection>
        <styled.FormLeft>
          <styled.FormTitle>Ready to become a Partner?</styled.FormTitle>
          <styled.FormDescription>
            Contact us to learn more about the CodePlatform Partner program.
          </styled.FormDescription>
        </styled.FormLeft>
        <styled.FormRight>
          <Form
            id="concierge"
            inputs={[
              {
                placeholder: 'Subject',
                name: 'subject',
                value: 'Concierge Services',
                hidden: true,
              },
              { placeholder: 'Name*', name: 'firstname' },
              { placeholder: 'Work email*', type: 'email', name: 'email' },
              { placeholder: 'Company Name*', name: 'company' },
            ]}
            formData={{
              data: formData.data,
              handleSubmit: formData.handleSubmit,
              isLoading: formData.isLoading,
            }}
            textareaText="What can we help you achieve?"
            textareaSmall
            buttonText="Submit"
            buttonNotCenteredMedium
          />
        </styled.FormRight>
      </styled.FormSection>
    </Layout>
  );
};
