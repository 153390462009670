import { Name } from '../general';

export const CHALLENGES = [
  {
    icon: Name.CONCIERGE_UX,
    title: 'UX/UI Design',
    description:
      'We translate the complex into the simple while putting design first for 15 years. Let us help you elevate your UX/UI to pixel perfection.',
  },
  {
    icon: Name.CONCIERGE_INTEGRATIONS,
    title: 'Custom integrations',
    description:
      'Pick a service you like and let us do the boring part. Ask our experts to add a custom integration for you.',
  },
  {
    icon: Name.CONCIERGE_FEATURES,
    title: 'Custom features',
    description:
      "Do you have a vital feature that we don't yet cover? Let us develop it for you or add it to our roadmap for later.",
  },
  {
    icon: Name.CONCIERGE_MOBILE_DEVELOPMENT,
    title: 'Mobile Development',
    description:
      "Let us be your dream team if you don't yet have it. Save at least 480 hours of development with CodePlatform while being ensured about code quality.",
  },
  {
    icon: Name.CONCIERGE_WEB_DEVELOPMENT,
    title: 'Web Development',
    description:
      'PWA, SPA, SSR, SSG, DSG - we develop more than just mobile apps. We are experts in building complex web projects, browser plugins, and serverless backends.',
  },
  {
    icon: Name.CONCIERGE_LEGACY,
    title: 'Legacy apps migration',
    description:
      'Already have an app? We can help update your existing app seamlessly. Replace the outdated parts with new well-made modules while keeping your data intact.',
  },
];

export const PARTNER_CHALLENGES = [
  {
    icon: Name.PARTNERS_PROGRAM_AGENCY,
    title: 'Agency',
    description:
      'A certified dev shop seeking to accelerate development with the help of CodePlatform & find new clients.',
  },
  {
    icon: Name.PARTNERS_PROGRAM_INDEDPENDENT_CONSULTANT,
    title: 'Independent Consultant',
    description:
      'A certified low-code or no-code developer looking to gain a new source of income.',
  },
  {
    icon: Name.PARTNERS_PROGRAM_VENDOR,
    title: 'Vendor',
    description:
      'A certified company with white-label services for different markets built on top of CodePlatform PaaS.',
  },
];

export const TABLE = [
  ['Phase', 'Custom Development', 'CodePlatform'],
  [
    'Research & Prototyping',
    '<b>80+ hours</b>',
    "<b>0 hours.</b><p>Our team has spent at least 480h on research and prototyping while creating CodePlatform's reusable modules that follow best practices. You can skip this phase and focus on your killer feature.</p>",
  ],
  [
    'UX/UI Design',
    '<b>80+ hours</b>',
    "<b>0 hours.</b><p>We've created the design system of main modules so that it can be easily tuned to the style of your app, while avoiding mistakes in UX. The modules follow IOS and Android guidelines and best practices. The design looks native to app users regardless of platform.</p>",
  ],
  [
    'Software Architecture',
    '<b>40+ hours</b>',
    "<b>0 hours.</b><p>App logic, system design, scalability and security - you get all of this for free by using project templates bundled into pre-built modules.</p><p>We've been architecting complex software products for 15 years.</p><p>In this time, we've devised a perfect combination of third-party tools that helps you move faster and avoid common pitfalls.</p>",
  ],
  [
    'DevOps',
    '<b>32+ hours</b>',
    '<b>0 hours.</b><p>Building and deployment made simple. Take DevOps out-of-the-box at CodePlatform CI</p>',
  ],
  [
    'Onboarding',
    '<b>24+ hours</b>',
    "<b>0 hours.</b><p>Proper user onboarding is the first step on the way to success.</p><p>We've got the right onboarding flow ready for you.</p>",
  ],
  [
    'Advanced and secured Authentication Flow',
    '<b>40+ hours</b>',
    '<b>0 hours.</b><p>Multiple authentication providers and their screens are already inside your app. Select the ones you use and get the rest covered, even the pitfalls. In the end, you are issued a JWT token that is easily validated on the backend or in any 3rd party integrations.</p>',
  ],
  [
    'Soft Permission Requests',
    '<b>16+ hours</b>',
    "<b>0 hours.</b><p>It's common courtesy to use soft permissions requests, which explain the reasons behind requesting access. This practice is vital to get a smaller refusal rate and pass Appstore and Google Play reviews easily. With CodePlatform, you get this feature by default.</p>",
  ],
  [
    'Push Notifications management',
    '<b>24+ hours</b>',
    "<b>0 hours.</b><p>Push notifications sound like a single code line. In reality, if you're not planning to mass-mail a single notification to your users, it gets more complicated, the more features you add. You have to handle multiple users' devices, log out and app deletion. If you're building a messenger with  voice calls and chats, there are also VoIP Push Notifications and Push Extensions to add. You end up doing a great deal of hidden work, which is covered if you use CodePlatform.</p>",
  ],
  [
    'Paywall & Revenue tracking',
    '<b>24+ hours',
    '<b>up to 30 minutes.</b><p>A proper paywall is the first step on the way to get higher revenue. Unify the subscription validation process for all of your apps worry-free. Market-proven paywall templates that make $100k of MRR are awaiting you at CodePlatform.</p>',
  ],
  [
    'Home screen & Navigation',
    '<b>8+ hours</b>',
    "No overthinking on your part. We've already made navigation extendable and familiar to users.",
  ],
  [
    'User Profile',
    '<b>24+ hours</b>',
    '<b>0 hours.</b><p>A basic user profile supporting multi-device and subscription (access level) sync across all devices is created automatically. You can easily extend it if you need something unique.</p>',
  ],
  [
    'Terms & Policies',
    '<b>16+ hours</b>',
    '<b>0 hours.</b><p>Humanly understandable terms and policies generated for you within CodePlatform. All in compliance with Appstore and Google Play regulations, they are automatically linked with the app, its landing page and the app stores. No need to recreate them every time.</p>',
  ],
  [
    'Support Form',
    '<b>16+ hours</b>',
    "<b>0 hours.</b><p>We've added a support form to the user profile to provide a faster way to communicate with the creator. Collect the feedback with a single integration.</p>",
  ],
  [
    'Light and Dark modes support',
    '<b>16+ hours</b>',
    '<b>0 hours.</b><p>Our design system is optimized for work in dark and light modes from the git-go. Every user is enabled to adjust the mode right from their settings, no additional code needed.</p>',
  ],
  [
    'Chat and Conversations',
    '<b>480+ hours</b>',
    "<b>up to 30 minutes.</b><p>You get much more than a support chat. We've created modules with true messenger behaviour, letting you add private conversations and audio/video calls. Adjust the business logic to your needs in less than an hour.</p>",
  ],
  [
    'Audio & Video calls',
    '<b>160+ hours</b>',
    '<b>up to 30 minutes</b>.<p>Add video calls to your app in a single click.</p>',
  ],
  [
    'End-to-end encryption',
    '<b>80+ hours</b>',
    '<b>0 hours.</b><p>Security is our priority. Enable end-to-end encryption for calls and chats.</p>',
  ],
  [
    'Push Notification Extension',
    '<b>24+ hours</b>',
    "<b>0 hours.</b><p>If you're using end-to-end encryption, you should do additional work handling Push Notifications. Get all the pitfalls covered.</p>",
  ],
  [
    'Audio & Video podcasts',
    '<b>320+ hours</b>',
    "<b>0 hours.</b><p>A ready-made module for content creators, which follows best practices in UI/UX. Sell subscription-based audio and video content. Manage it inside CodePlatform's CRM module and make more time for content creation.</p>",
  ],
  [
    'Content Management System',
    '<b>40+ hours</b>',
    '<b>up to 60 minutes.</b><p>No need to develop a custom admin panel to manage the content. Headless CMS & the option to connect any data source visually are available for you.</p>',
  ],
  [
    'App landing page',
    '<b>24+ hours</b>',
    '<b>0 hours.</b><p>Get the application homepage with everything required by app stores. It is updated simultaneously with your app.</p>',
  ],
  [
    'QA',
    '<b>at least 10% of the project scope</b>',
    "<b>0 hours on CodePlatform's modules.</b><p>Our team has already QA'ed every one of them.</p>",
  ],
  [
    'Maintenance and support.',
    '<b>Endless</b>',
    '<b>0 hours.</b><p>We constantly release updates of our modules with bug fixes and new features & timely adjust them to support the new releases of IOS and Android.</p>',
  ],
  [
    'App Screenshots Generation and Maintenance',
    '<b>8+ hours</b>',
    "<b>up to 30 minutes.</b><p>Get more downloads using ASO screenshots templates. Best practices to make your screenshots catch the eye of users and increase in-app conversions.</p><p>Screenshots are updated automatically with your app, including <a href='/store-manager'>Store Manager</a> and <a href='/app-landing-page'>App Landing page</a>. No need to recreate them manually every time.</p>",
  ],
  [
    'App & Play store publishing',
    '<b>24+ hours</b>',
    '<b>up to 30 minutes.</b><p>Manage store content in one place. Connect App Store and Google Play with a simple integration. We will do all the hidden job to help you with publishing.</p>',
  ],
  [
    'Project Management',
    '<b>at least 10% of the project scope</b>',
    '<b>0 hours.</b><p>Our team is constantly developing cool new templates and features for CodePlatform. You save time by choosing the ones that fit instead of creating them from scratch.</p>',
  ],
];
