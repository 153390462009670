import styled from 'styled-components';

import * as text from '@/components/general/TextStyles';
import { BorderLink } from '@/components/general';

export const ContactUs = styled(BorderLink)`
  margin-top: 10px;
`;

export const BlueSection = styled.div`
  width: 100vw;
  background-color: ${(props) => props.theme.colors.blue};
  color: ${(props) => props.theme.colors.white};
  margin-top: 45px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    margin-top: 35px;
  }
`;

export const BlueSectionRound = styled.div<{ orientation: 'top' | 'bottom' }>`
  width: 100%;

  & > div {
    ${(props) =>
      props.orientation === 'top'
        ? 'scroll-margin-bottom: 60px;'
        : 'scroll-margin-top: 60px;'}
    position: relative;
    ${(props) => (props.orientation === 'top' ? 'top: -1px;' : 'bottom: 0px;')}
    ${(props) => props.orientation === 'top' && 'transform: rotate(180deg);'}
    

    & svg {
      width: 100%;
      height: auto;
      display: block;

      & path {
        ${(props) => props.orientation === 'top' && 'fill: #fffff;'}
      }
    }

    &::after {
      content: '';
      position: absolute;
      ${(props) =>
        props.orientation === 'top' ? 'bottom: 100%;' : 'top: 100%;'}
      right: 0;
      ${(props) => (props.orientation === 'top' ? 'top: 0px;' : 'bottom: 0px;')}
      left: 0;
    }
  }
`;

export const BlueBlurEllipse = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  width: 1942px;
  height: 821px;
  position: absolute;
  pointer-events: none;
  left: -502.97px;
  top: 666.02px;
  left: calc(-400 / 1440 * 100%);
  top: calc(205 / 1440 * 100%);
  border-radius: 50%;
  transform: rotate(-23.71deg);
  filter: blur(150px);
  will-change: transform;
  opacity: 0.2;
  z-index: 1;

  @media (max-width: 851px) {
    height: 50%;
    transform: rotate(-60deg) translate(-40%, -30%);
    left: 0px;
    top: 0px;
  }
`;

export const BlueContent = styled.div`
  position: relative;
  z-index: 12;
  padding: 80px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BlueTitle = styled.h2`
  ${text.FS40}

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    text-align: center;
  }
`;

export const BlueGrid = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 85px 95px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    row-gap: 45px;
  }
`;

export const GridItem = styled.div`
  max-width: 350px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const GridIconBox = styled.div`
  margin-bottom: 30px;
  width: 90px;
`;

export const GridTitle = styled.h3`
  ${text.FS30}
  margin-bottom: 15px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    text-align: center;
  }
`;

export const GridDescription = styled.p`
  ${text.FS18}
  color: ${(props) => props.theme.colors.offWhite};

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    text-align: center;
  }
`;

export const TableSection = styled.section`
  margin-top: 40px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    margin-top: 35px;
  }
`;

export const TableTItle = styled.h2`
  ${text.FS42_LG}
  text-align: center;
  margin-bottom: 20px;
`;

export const TableAnchor = styled.div`
  position: absolute;
  top: ${(props) => -(props.theme.headerSize.heightDesktop + 10)}px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    top: ${(props) => -(props.theme.headerSize.heightMobile + 10)}px;
  }
`;

export const TableBox = styled.div`
  margin-top: 60px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin-top: 40px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    margin-top: -10px;
  }
`;

export const FormSection = styled.div`
  max-width: 1105px;
  margin: 100px auto 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 60px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    row-gap: 40px;
  }
`;

export const FormTitle = styled.h2`
  ${text.FS24}
  font-weight: ${(props) => props.theme.weights.bold};
  margin-bottom: 20px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    text-align: center;
  }
`;

export const FormLeft = styled.div`
  font-weight: ${(props) => props.theme.weights.regular};
  max-width: 430px;
  margin-top: 30px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    max-width: none;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    margin-top: 0px;
  }
`;

export const FormRight = styled.div`
  min-width: 540px;

  @media (max-width: ${(props) => props.theme.breakpoints.xl}px) {
    min-width: ${(props) =>
      `calc(540px + (100vw - ${props.theme.breakpoints.xl}px)/2)`};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    min-width: 400px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    min-width: 0;
    width: 100%;
    max-width: 400px;
  }
`;

export const FormDescription = styled.p`
  ${text.FS16}
  line-height: 24px;
  color: ${(props) => props.theme.colors.grayText};

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    text-align: center;
  }
`;
