import React from 'react';

import { ThemeProvider } from '@/themes/theme';
import { Meta } from '@/components/general/Meta';
import { GlobalStyle } from '@/themes/globalStyle';
import Wrapper from '@/components/Wrapper';
import { Header } from '@/components/Header';
import { Content } from '@/components/PartnersProgram';
import { Footer } from '@/components/Footer';
import { Links } from '@/utils/constants';

const PartnersProgram = () => (
  <ThemeProvider>
    <Meta
      title="Partners Program | CodePlatform"
      description="Launch your project faster with the help of our cutting-edge expertise in full-stack applications development."
      url={Links.PARTNERS_PROGRAM}
    />
    <GlobalStyle />
    <Wrapper>
      <Header />
      <Content />
      <Footer />
    </Wrapper>
  </ThemeProvider>
);

export default PartnersProgram;
